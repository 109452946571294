import React, { useState } from 'react';

import './Skills.css';

function Skills() {
  const [state] = useState({
    frontEnd: [
      { HTML5: '65' },
      { CSS3: '65' },
      { Bootstrap: '65' },
      { Tailwind: '55' },
      { JavaScript: '60' },
      { TypeScript: '55' },
      { React: '60' },
      { Testing: '40' },
      { Canva: '40' },
      { Wordpress: '40' },
    ],
    backEnd: [
      { 'Node.js': '55' },
      { Express: '55' },
      { 'NEXT.js': '50' },
      { Postgres: '45' },
      { MongoDB: '40' },
      { RESTful: '55' },
      { Firebase: '45' },
      { AWS: '35' },
      { Docker: '45' },
      { 'CI/CD': '45' },
    ],
    tools: [
      { Git: '55' },
      { 'VS Code': '55' },
      { Jira: '50' },
      { Miro: '50' },
      { Agile: '55' },
      { Scrum: '55' },
      { 'Team Work': '60' },
      { AI: '55' },
      { English: '65' },
      { Swedish: '45' },
    ],
  });
  return (
    <div
      className='pt-5 mb-5 bg-col'
      id='skills'
      style={{ backgroundColor: '#ffffff' }}
    >
      <div className='container pt-5  pb-5 button w-75'>
        <h1 className='  font-weight-bold '>SKILLS</h1>
        <div className='underline-bar mb-5'></div>
        <div className=''></div>
        <div className=''>
          <div className=' pt-5 justify-content-around row'>
            <div className=''>
              <div className='col mt-5 skill-components '>
                <i className='rotate-icon fas fa-code'></i>
              </div>
              <h3 className='mt-4 pt-4 mb-3 pb-3 font-bebas'>Front end</h3>
              <ul className='mb-5 col list-group skill-list'>
                {state.frontEnd.map((item, index) => (
                  <li
                    className=' list-group-item border-0'
                    key={index}
                    style={{ backgroundColor: '#feffff' }}
                  >
                    <div
                      className='progress pro-h rounded-0'
                      style={{ backgroundColor: '#def2f1' }}
                    >
                      <div
                        className='progress-bar w-25 font-weight-bold'
                        role='progressbar'
                        style={{ backgroundColor: '#2b7a78' }}
                      >
                        {Object.keys(item)}
                      </div>
                      <div
                        className='progress-bar-striped'
                        role='progressbar'
                        style={{
                          backgroundColor: '#3aafa9',
                          width: Object.values(item) + '%',
                        }}
                      ></div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div className='col mt-5 skill-components'>
                <i className='rotate-icon fas fa-server'></i>
              </div>
              <h3 className='mt-4 pt-4 mb-3 pb-3 font-bebas'>Back end</h3>
              <ul className='mb-5 col list-group skill-list'>
                {state.backEnd.map((item, index) => (
                  <li
                    className=' list-group-item border-0'
                    key={index}
                    style={{ backgroundColor: '#feffff' }}
                  >
                    <div
                      className='progress pro-h rounded-0'
                      style={{ backgroundColor: '#def2f1' }}
                    >
                      <div
                        className='progress-bar w-25 font-weight-bold'
                        role='progressbar'
                        style={{ backgroundColor: '#2b7a78' }}
                      >
                        {Object.keys(item)}
                      </div>
                      <div
                        className='progress-bar-striped '
                        role='progressbar'
                        style={{
                          backgroundColor: '#3aafa9',
                          width: Object.values(item) + '%',
                        }}
                      ></div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div className='col mt-5 skill-components'>
                <i className='rotate-icon fas fa-tools '></i>
              </div>
              <h3 className='mt-4 pt-4 mb-3 pb-3 font-bebas'>Other</h3>
              <ul className='mb-5 col list-group skill-list '>
                {state.tools.map((item, index) => (
                  <li
                    className=' list-group-item border-0'
                    key={index}
                    style={{ backgroundColor: '#feffff' }}
                  >
                    <div
                      className='progress pro-h rounded-0'
                      style={{ backgroundColor: '#def2f1' }}
                    >
                      <div
                        className='progress-bar w-25 font-weight-bold'
                        role='progressbar'
                        style={{ backgroundColor: '#2b7a78' }}
                      >
                        {Object.keys(item)}
                      </div>
                      <div
                        className='progress-bar-striped'
                        role='progressbar'
                        style={{
                          backgroundColor: '#3aafa9',
                          width: Object.values(item) + '%',
                        }}
                      ></div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
